import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import { useTranslation } from 'react-i18next';
import { ClientContactRequest, ClientContactResponse } from '../../models/ClientContacts';
import { isEmailValid } from '../../utils/EmailUtils';
import ClientContactService from '../../services/ClientContactService';
import ClientTagService from '../../services/ClientTagService';
import ContactGeneralDetails from './ContactGeneralDetails';
import { ClientTagResponse } from '../../models/ClientTag';
import { useQueryClient } from '@tanstack/react-query';
import { useCurrentClient } from '../../global-state/Clients';

type Props = {
  open: boolean;
  onClose: () => void;
  contactCreated: (contact: ClientContactResponse) => void;
};

const EMPTY_CONTACT: ClientContactRequest = {
  email: '',
  firstName: '',
  lastName: '',
  properties: {
    language: '',
    company: '',
    jobTitle: '',
    phoneNumber: '',
  },
};

const AddContactModal: FC<Props> = (props) => {
  const { open, onClose, contactCreated } = props;
  const queryClient = useQueryClient();

  const { t } = useTranslation('organisation');
  const currentClient = useCurrentClient((x) => x.value);

  const [newContact, setNewContact] = useState<ClientContactRequest>(EMPTY_CONTACT);
  const [tagsToApply, setTagsToApply] = useState<string[]>([]);

  useEffect(() => {
    if (!open) {
      setNewContact(EMPTY_CONTACT);
      setTagsToApply([]);
    }
  }, [open]);

  const valid = useMemo(() => {
    return !!newContact.firstName.trim() && !!newContact.lastName.trim() && !!newContact.email.trim() && isEmailValid(newContact.email);
  }, [newContact.email, newContact.firstName, newContact.lastName]);

  const createContact = useCallback(() => {
    ClientContactService.createContact(currentClient!.id, newContact)
      .then(async (res) => {
        await Promise.all(tagsToApply.map((tagId) => ClientTagService.linkTag(currentClient!.id, tagId, res.data.id)));
        contactCreated(res.data);
      })
      .finally(() => {
        onClose();
        // Invalidate the client contacts query to refetch the data
        queryClient.invalidateQueries({ queryKey: ['clientContacts', currentClient!.id] });
      });
  }, [contactCreated, currentClient, newContact, onClose, queryClient, tagsToApply]);

  const onTagsChanged = useCallback((tags: ClientTagResponse[]) => {
    setTagsToApply(tags.map((x) => x.id));
  }, []);

  return (
    <ModalContext.Provider value={{ open, onClose, modalWidth: 'w-2/5' }}>
      <StandardModal
        title={t('contacts.add-contact-modal.title')}
        confirmDisabled={!valid}
        confirmButtonTitle={t('contacts.add-contact-modal.buttons.create')}
        confirmDisabledTooltip={t('contacts.add-contact-modal.buttons.create-disabled-tooltip')}
        onConfirmClick={createContact}
        onCancelClick={onClose}
      >
        <ContactGeneralDetails contact={newContact} onChange={setNewContact} onTagsChanged={onTagsChanged} />
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default AddContactModal;
